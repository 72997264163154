body {
    background-color: #FFF;
}

.MuiPagination-ul {
    justify-content: center;
}

.MuiButtonBase-root {
    height: 2rem;
    padding: 0.5rem 1rem;
}

.MuiListItem-root {
    height: 3rem;
}

.MuiPaginationItem-root {}

.MuiPaginationItem-page:hover {
    background: #2687E926 !important;
}

.MuiPaginationItem-outlined.Mui-selected {
    background: #2687E94D !important;
    border: 1px solid #2687E9;
}

.p-0 {
    padding: 0 !important;
}

button {
    text-transform: uppercase !important;
}

/*/Dashboard SLA*/
.dashboard-sla-section-item-label {
  color: var(--text-soft, #585B65);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.dashboard-sla-section-item-quantity {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.dashboard-sla-section-item-title {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.dashboard-sla-section-item-bg-green {
  border-radius: 8px;
  border: 1px solid #289548;
}

.dashboard-sla-section-item-bg-red {
  border-radius: 8px;
  border: 1px solid #B00020;
}

.dashboard-sla-section-item-bg-default {
  border-radius: 8px;
  border: 1px solid #21212114;
  background: #FFFFFF;
}

.MuiOutlinedInput-root,
.MuiOutlinedInput-notchedOutline {
    border-radius: 8px !important;
    border-color: #e5e7eb !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #3FB54B !important;
    border-width: 1px !important; 
}

.MuiStep-horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: -20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.close-button-chart {
  font-size: 18px !important;
  margin-top: 5px;
  margin-left: 5px;
  cursor: pointer;
  color: red;
}

.cursor-default {
  cursor: default !important;
}
