.react-datepicker__header {
    background-color: inherit;
    border-bottom: none;
}

.react-datepicker__day--selected, .react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range, .react-datepicker__day--keyboard-selected {
    background-color: #3FB54B;
    border-radius: 50%;
    color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-range:hover {
    border-radius: 50%;
    background-color: #3FB54B;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: rgba(63, 181, 75, 0.5) !important;
    border-radius: 50%;
}